import React from 'react';
import jwt_decode from 'jwt-decode';

import { config } from '../Config';
import {
  getRequestErrorData,
  isInteractionRequired,
  normalizeError,
} from '../utils/errorMessageHandler';
import LocalStorageService from '../services/localStorageService';
import AzureMsalService from '../services/azureMsalService';
import { userService } from '../services/user.service';
import { User } from '../types/types';

export const AZURE_ACCESS_TOKEN = 'azure-access-token';

export interface AuthComponentProps {
  error: any;
  isAuthenticated: boolean;
  backendAuthErr: boolean;
  authLoading: boolean;
  user: User;
  login: () => void;
  logout: () => void;
  getAccessToken: (scopes: string[]) => Promise<string>;
  setError: (message: string, debug: string) => void;
}

interface AuthProviderState {
  error: any;
  isAuthenticated: boolean;
  backendAuthErr: boolean;
  authLoading: boolean;
  user: User;
}

// eslint-disable-next-line
function withAuthProvider<T extends React.Component<AuthComponentProps>>(
  WrappedComponent: React.ComponentType<AuthComponentProps>
): React.ComponentClass {
  return class AuthProvider extends React.Component<any, AuthProviderState> {
    private azureMsalService: AzureMsalService;

    constructor(props: any) {
      super(props);
      this.state = {
        error: null,
        isAuthenticated: false,
        backendAuthErr: false,
        authLoading: true,
        user: {
          firstName: '',
          name: '',
          lastName: '',
          location: '',
          id: '',
        },
      };

      // Initialize the MSAL application object
      this.azureMsalService = new AzureMsalService();
    }

    componentDidMount() {
      this.azureMsalService
        .handleRedirectPromise()
        .then((response) => {
          // After login, get the user's profile
          if (response) {
            this.getUserProfile();
          }
        })
        .catch((error) => {
          // handle error, either in the library or coming back from the server
          this.setState({
            authLoading: false,
            isAuthenticated: false,
            user: {
              firstName: '',
              name: '',
              lastName: '',
              location: '',
              id: '',
            },
            error: normalizeError(error),
          });
        });

      const accounts = this.azureMsalService.getAllAccounts();

      if (accounts && accounts.length > 0) {
        this.getUserProfile();
      } else {
        this.setState({
          authLoading: false,
        });
      }
    }

    render() {
      return (
        <WrappedComponent
          login={() => this.login()}
          logout={() => this.logout()}
          getAccessToken={(scopes: string[]) => this.getAccessToken(scopes)}
          setError={(message: string, debug: string) =>
            this.setErrorMessage(message, debug)
          }
          {...this.props}
          {...this.state}
        />
      );
    }

    async login() {
      try {
        // After login, get the user's profile
        // Login via redirect page
        this.azureMsalService.loginRedirect();
      } catch (err: any) {
        this.setState({
          isAuthenticated: false,
          authLoading: false,
          user: {
            firstName: '',
            name: '',
            lastName: '',
            location: '',
            id: '',
          },
          error: normalizeError(err),
        });
      }
    }

    logout() {
      LocalStorageService.remove(AZURE_ACCESS_TOKEN);
      this.azureMsalService.logout();
    }

    async getAccessToken(scopes: string[]): Promise<any> {
      try {
        const accounts = this.azureMsalService.getAllAccounts();

        if (accounts.length <= 0) {
          throw new Error('login_required');
        }
        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        const silentResult = await this.azureMsalService.acquireTokenSilent({
          scopes: scopes,
          account: accounts[0],
        });

        return silentResult.accessToken;
      } catch (err: any) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        if (isInteractionRequired(err)) {
          this.azureMsalService.acquireTokenRedirect({
            scopes: scopes,
          });
        } else {
          throw err;
        }
      }
    }

    async getBackendUserData(accessToken: string): Promise<User> {
      const decodedToken: any = jwt_decode(accessToken);

      if (!decodedToken['unique_name']) {
        throw new Error('unique_name required');
      }

      if (!config.backendUrl) {
        throw new Error('No backendUrl provided');
      }

      try {
        const userId = decodedToken['unique_name']
          ? decodedToken['unique_name'].replace('@yara.com', '')
          : '';

        console.log('userId', userId);
        const responseData = await userService.getUserByUserId(userId);

        return responseData.data;
      } catch (err: any) {
        if (err.response?.status === 401) {
          throw new Error('backend auth failed');
        }

        throw err;
      }
    }

    async getUserProfile() {
      try {
        this.setState({
          authLoading: true,
        });
        const accessToken = await this.getAccessToken(config.scopes);
        if (accessToken) {
          LocalStorageService.set(AZURE_ACCESS_TOKEN, accessToken);

          const userData = await this.getBackendUserData(accessToken);

          // TEMPORARY: Display the token in the error flash
          this.setState({
            isAuthenticated: true,
            backendAuthErr: false,
            authLoading: false,
            error: { message: 'Access token:', debug: accessToken },
            user: userData,
          });
        }
      } catch (err: any) {
        // TODO: refactoring
        if (
          err.message === 'backend auth failed' ||
          err.message === 'Error: Forbidden_Factory'
        ) {
          this.setState({
            authLoading: false,
            isAuthenticated: false,
            backendAuthErr: true,
            user: {
              firstName: '',
              name: '',
              lastName: '',
              location: '',
              id: '',
            },
            error: normalizeError(err),
          });

          return;
        }

        this.setState({
          authLoading: false,
          isAuthenticated: false,
          user: {
            firstName: '',
            name: '',
            lastName: '',
            location: '',
            id: '',
          },
          error: getRequestErrorData(err),
        });

        LocalStorageService.remove(AZURE_ACCESS_TOKEN);
      }
    }

    setErrorMessage(message: string, debug: string) {
      this.setState({
        error: { message: message, debug: debug },
      });
    }
  };
}

export default withAuthProvider;
