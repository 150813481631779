import { AxiosError } from 'axios';

export const normalizeError = (error: string | Error): any => {
  if (typeof error === 'string') {
    const errParts = error.split('|');

    return errParts.length > 1
      ? { message: errParts[1], debug: errParts[0] }
      : { message: error };
  } else {
    return {
      message: error.message,
      debug: JSON.stringify(error),
    };
  }
};

export const isInteractionRequired = (error: Error): boolean => {
  if (!error.message || error.message.length <= 0) {
    return false;
  }

  return (
    error.message.indexOf('consent_required') > -1 ||
    error.message.indexOf('interaction_required') > -1 ||
    error.message.indexOf('login_required') > -1 ||
    error.message.indexOf('no_account_in_silent_request') > -1
  );
};

export const getRequestErrorData = (error: AxiosError) => {
  if (error.response) {
    return error.response.data;
  }
};
