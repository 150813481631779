import { makeStyles } from '@mui/styles';

const tableStyles = makeStyles((theme) => ({
  tableOverride: {
    '& .MuiDataGrid-cell': {
      '&:focus': {
        outline: 'none',
      },
    },
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,

      '& .MuiIconButton-label': {
        color: theme.palette.secondary.main,
      },
    },

    '& .MuiDataGrid-viewport': {
      color: theme.palette.primary.dark,
    },

    '& .MuiIconButton-label': {
      color: theme.palette.primary.dark,
    },

    '& .MuiDataGrid-row': {
      '&:nth-child(odd)': {
        backgroundColor: theme.palette.primary.light,
      },

      '&:nth-child(even)': {
        backgroundColor: theme.palette.secondary.main,
      },
    },

    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '& .MuiDataGrid-columnHeaderTitleContainer': {
      paddingLeft: '0',
    },

    '& .MuiDataGrid-menuIcon': {
      display: 'none',
    },

    '& .MuiDataGrid-iconButtonContainer button': {
      color: theme.palette.secondary.main,
    },
  },
  actionControls: {
    display: 'flex',

    '& .MuiSvgIcon-root': {
      '& :hover': {
        cursor: 'pointer',
      },
    },
  },
}));

export default tableStyles;
