import { Snackbar } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';
import { useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import React from 'react';

export interface SnackbarMessage {
  message: string;
  severity: AlertColor;
}

export const withSnackbar = (WrappedComponent: any) => {
  const snackbarWrapper = (props: any) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('Something happened');
    const [severity, setSeverity] = useState<AlertColor>('success');

    const showMessage = (message: SnackbarMessage) => {
      setMessage(message.message);
      setSeverity(message.severity);
      setOpen(true);
    };

    const handleClose = (event: any, reason: any) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    return (
      <>
        <WrappedComponent {...props} snackbarShowMessage={showMessage} />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <MuiAlert elevation={6} variant="filled" severity={severity}>
            {message}
          </MuiAlert>
        </Snackbar>
      </>
    );
  };

  return snackbarWrapper;
};
