import { UserListFilterType } from '../../types/types';
import { SET_FILTER_DATA } from './types';

type EmptyObject = {
  [K in any]: never;
};

const initialState = {
  to: 15, // todo move it to one place
  from: 0,
  search: '',
  orderBy: '',
  sortBy: '',
  plant: '',
};
const reducer = (
  state: UserListFilterType = initialState,
  action: any
): UserListFilterType | EmptyObject => {
  switch (action.type) {
    case SET_FILTER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
