import makeStyles from '@mui/styles/makeStyles';
import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.main,
  },
}));

interface BackdropSpinnerProps {
  isOpened: boolean;
}

const BackdropSpinner = ({ isOpened }: BackdropSpinnerProps) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isOpened}>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
};

export default BackdropSpinner;
