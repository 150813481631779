const {
  REACT_APP_AZURE_TENANT_ID,
  REACT_APP_REDIRECT_URI,
  REACT_APP_ID,
  REACT_APP_BACKEND_URL,
  REACT_APP_AZURE_RESOURCE,
} = process.env;

const scopes =
  REACT_APP_AZURE_RESOURCE && REACT_APP_AZURE_RESOURCE !== 'null'
    ? [`${REACT_APP_AZURE_RESOURCE}/user_impersonation`]
    : ['openid', 'profile', 'user.read'];

export const config = {
  appId: REACT_APP_ID,
  redirectUri: REACT_APP_REDIRECT_URI,
  scopes,
  azureTenantId: REACT_APP_AZURE_TENANT_ID,
  backendUrl: REACT_APP_BACKEND_URL,
};
