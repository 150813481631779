import { createStore, combineReducers } from 'redux';
import session from './session';
import userFilter from './user-filter';

const rootReducer = combineReducers({
  session,
  userFilter,
});

const store = createStore(rootReducer);

export default { store };
