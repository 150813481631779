import { createTheme } from '@mui/material/styles';

const primaryMainColor = '#2776B9';
const primaryLightColor = '#f8fcff';
const secondaryMainColor = '#ffffff';

import { Theme } from '@mui/material/styles';

// need this because there aren't a lot of themes in the application, and default theme
//  don't extend Theme interface and in that case typeScript throw a compilation error
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: {
      main: primaryMainColor,
      light: primaryLightColor,
      dark: '#004b89',
    },
    secondary: {
      main: secondaryMainColor,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-formControl': {
            borderRadius: '0',
          },
          input: {
            padding: 8,
            borderRadius: '0',
            fontSize: '12px',
          },
          '& .MuiOutlinedInput-multiline': {
            padding: '5px',
            fontSize: '12px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          lineHeight: '19px',
          padding: '18.5px 14px',
          '& .MuiInputBase-root': {
            borderRadius: 0,
          },
        },
        select: {
          '&:focus': {
            backgroundColor: secondaryMainColor,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '10px 32px',
          fontSize: '16px',
          minHeight: '24px',

          button: {
            '&:hover': {
              backgroundColor: primaryLightColor,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0',
          textTransform: 'capitalize',
          padding: '5px 18px',
          boxShadow: 'none',
          borderWidth: '1px',
        },
        outlined: {
          border: '2px solid #2776B9',
        },
        containedPrimary: {
          color: secondaryMainColor,
          backgroundColor: primaryMainColor,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: primaryMainColor,
          borderRadius: '0 !important',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '0',
          boxShadow: 'none',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          padding: '33px 23px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: primaryMainColor,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          color: primaryMainColor,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: primaryMainColor,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: primaryMainColor,
          },
        },
      },
    },
  },
});

export default theme;
