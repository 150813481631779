import axios, { AxiosRequestConfig } from 'axios';
import { config } from '../Config';
import { AZURE_ACCESS_TOKEN } from '../hoc/AuthProvider';
import LocalStorageService from './localStorageService';

const AUTH_HEADER = 'Authorization';

export const requestHandler = {
  get: (url: string, requestConfig?: AxiosRequestConfig) =>
    axios.get(`${config.backendUrl}${url}`, {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        ...requestHandler.getAuthHeaders(),
      },
    }),
  post: (url: string, body: any, requestConfig?: AxiosRequestConfig) =>
    axios.post(`${config.backendUrl}${url}`, body, {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        ...requestHandler.getAuthHeaders(),
      },
    }),
  put: (url: string, body: any, requestConfig?: AxiosRequestConfig) =>
    axios.put(`${config.backendUrl}${url}`, body, {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        ...requestHandler.getAuthHeaders(),
      },
    }),
  delete: (url: string) =>
    axios.delete(`${config.backendUrl}${url}`, {
      headers: {
        ...requestHandler.getAuthHeaders(),
      },
    }),
  getAuthHeaders: () => {
    const accessToken = LocalStorageService.get(AZURE_ACCESS_TOKEN);

    return {
      [AUTH_HEADER]: `${accessToken}`,
    };
  },
};
