import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import ROUTES from '../App/routes';

const AuthRoute = ({ isAuthorised, children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthorised ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default AuthRoute;
