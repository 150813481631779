import { useState, useEffect } from 'react';
import { plantApiService } from '../services/plant.service';
import useQuery from './useQuery';

const usePlants = () => {
  const { response, isLoading, error } = useQuery(() =>
    plantApiService.getPlants()
  );

  const [plants, setPlants] = useState<string[]>();

  useEffect(() => {
    if (!response) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setPlants(response.data);
  }, [response]);

  return { plants, error, isLoading };
};

export default usePlants;
