import { AxiosResponse } from 'axios';
import { requestHandler } from './requestHandler';

const _basePlantUrl = '/api/plants';

export const plantApiService = {
  getPlants: (): Promise<AxiosResponse<string[]>> => {
    return requestHandler.get(`${_basePlantUrl}`);
  },
};
