import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormHelperText,
  Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../../components/FormInput/FormInput';
import FormSelect from '../../components/FormSelect/FormSelect';
import { SnackbarMessage } from '../../hoc/withSnackbar';
import { userService } from '../../services/user.service';
import { User } from '../../types/types';

const ChangeUserDialog = (props: {
  plants: string[];
  editableUser?: User;
  onClose: (user?: User) => void;
  snackbarShowMessage: (message: SnackbarMessage) => void;
}) => {
  const { editableUser, onClose } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [serverValidationError, setServerValidationErrors] = useState<any>();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: editableUser?.firstName,
      lastName: editableUser?.lastName,
      id: editableUser?.id,
      plant: editableUser?.plant || '',
    },
  });

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (user: User) => {
    if (Object.keys(errors).length !== 0) {
      return;
    }

    editableUser ? updateUser(user) : createUser(user);
  };

  const createUser = (user: User) => {
    setLoading(true);
    userService
      .createUser(user)
      .then(() => {
        props.onClose(user);
        setServerValidationErrors(undefined);
      })
      .catch((error) => {
        props.snackbarShowMessage({
          message: 'User create failed',
          severity: 'error',
        });
        setServerValidationErrors(error.response?.data.validation);
      })
      .finally(() => setLoading(false));
  };

  const updateUser = (user: User) => {
    if (!editableUser?.id) {
      return;
    }

    const userData = {
      editableUser: editableUser.id,
      ...user,
    };

    setLoading(true);
    userService
      .updateUser(editableUser.id, userData)
      .then(() => {
        props.onClose(user);
      })
      .catch((error) => {
        props.snackbarShowMessage({
          message: 'User update failed',
          severity: 'error',
        });
        setServerValidationErrors(error.response?.data.validation);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <DialogTitle>{editableUser ? 'Edit User' : 'Add User'}</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={3}>
          <FormControl
            className="formItem"
            variant="outlined"
            fullWidth
            error={!!errors.firstName}
          >
            <FormInput
              label={'First name'}
              register={register('firstName', {
                required: true,
              })}
              required={true}
            />
            {errors.firstName?.type === 'required' && (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>
          <FormControl
            className="formItem"
            variant="outlined"
            fullWidth
            error={!!errors.lastName}
          >
            <FormInput
              label={'Last name'}
              register={register('lastName', {
                required: true,
              })}
              required={true}
            />
            {errors.lastName?.type === 'required' && (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>
          <FormControl
            className="formItem"
            variant="outlined"
            fullWidth
            error={!!errors.id || !!serverValidationError?.id}
          >
            <FormInput
              label={'A/C Number'}
              register={register('id', {
                required: true,
                pattern: /^[A,C][0-9]{6}$/i,
              })}
              required={true}
            />
            {errors.id?.type === 'required' && (
              <FormHelperText>Required</FormHelperText>
            )}
            {errors.id?.type === 'pattern' && (
              <FormHelperText>
                Incorrect A/C-Number pattern. Please use A or C character and 6
                digits
              </FormHelperText>
            )}
            {serverValidationError?.id && (
              <FormHelperText>{serverValidationError.id}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            className="formItem"
            variant="outlined"
            fullWidth
            error={!!errors.plant}
          >
            <FormSelect
              control={control}
              controlName="plant"
              label="Plant"
              options={props.plants || []}
              required={true}
            />
            {errors.plant && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'outlined'}
          onClick={handleClose}
          color="primary"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant={'contained'}
          color="primary"
          autoFocus
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default ChangeUserDialog;
