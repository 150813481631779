import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import withAuthProvider, { AuthComponentProps } from '../../hoc/AuthProvider';
import theme from '../../material-theme';
import UsersAdmin from '../../pages/users-admin/users-admin';
import AuthRoute from '../AuthRoute/AuthRoute';
import Header from '../Header/Header';
import Login from '../Login/Login';
import Spinner from '../Spinner/Spinner';
import ROUTES from './routes';

const useStyles = makeStyles(() => ({
  page: {
    padding: '33px 23.5px',
  },
}));

const App = (props: AuthComponentProps) => {
  const logOut = async () => {
    props.logout();
  };

  const classes = useStyles();

  return (
    <Router>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Header {...props} logout={logOut} />
          {props.authLoading ? (
            <Spinner />
          ) : (
            <>
              <Route path={ROUTES.login}>
                {!props.isAuthenticated ? (
                  <Login
                    loginMethod={props.login}
                    logoutMethod={logOut}
                    backendAuthErr={props.backendAuthErr}
                    error={props.error}
                  />
                ) : (
                  <Redirect to="/" />
                )}
              </Route>
              <AuthRoute path="/" exact isAuthorised={props.isAuthenticated}>
                <div className={classes.page}>
                  <UsersAdmin />
                </div>
              </AuthRoute>
            </>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  );
};

export default withAuthProvider(App);
