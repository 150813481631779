import React from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

const ConfirmationDialog = (props: {
  onClose: (result: boolean, confirmObject: any) => void;
  confirmObject: any;
  labelMessage: string;
  confirmationText: string;
}) => {
  const { onClose, confirmObject, labelMessage, confirmationText } = props;

  const handleClose = (result: boolean) => {
    onClose(result, confirmObject);
  };

  return (
    <>
      <DialogTitle>{labelMessage}</DialogTitle>
      <DialogContent dividers>{confirmationText}</DialogContent>
      <DialogActions>
        <Button
          variant={'outlined'}
          onClick={() => handleClose(false)}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          variant={'contained'}
          onClick={() => handleClose(true)}
          color="primary"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmationDialog;
