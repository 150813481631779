import { Dialog } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridSortModel, GridColDef, DataGrid } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import { UserListFilterType } from '../../types/types';
import ConfirmationDialog from '../ConfirmationDialog/confirmation-dialog';
import useStyles from './Table.styles';

export interface TableActions {
  edit: boolean;
  delete: boolean;
}

const Table = (props: {
  columns: any;
  rows: any;
  totalCount: number | undefined;
  actions?: TableActions;
  pageSize: number;

  onDelete?: (item: any | undefined) => void;
  onEdit?: (item: any | undefined) => void;
  onFilterChange: (filters: UserListFilterType) => void;
  onSortModelChange: (model: GridSortModel) => void;
  onPageChange: (newPage: number) => void;
}) => {
  const classes = useStyles();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [extendedColumns, setExtendedColumns] = useState<GridColDef[]>([]);

  const handleClickOpen = (params: any) => {
    setDeleteItem(params);
    setConfirmationOpen(true);
  };

  const handleClose = (answer: boolean, item: any) => {
    if (answer && props.onDelete) {
      props.onDelete(item);
    }

    setDeleteItem(undefined);
    setConfirmationOpen(false);
  };

  const extendColumnsWithActions = () => {
    return [
      ...props.columns,
      {
        field: 'action',
        headerName: ' ',
        sortable: false,
        align: 'right',
        renderCell: (params: any) => {
          const deleteComponent = props.actions?.delete ? (
            <DeleteIcon onClick={() => handleClickOpen(params)} />
          ) : (
            ''
          );

          const editComponent = props.actions?.edit ? (
            <EditIcon
              onClick={() => props.onEdit && props.onEdit(params.row)}
            />
          ) : (
            ''
          );
          return (
            <div className={classes.actionControls}>
              {deleteComponent}
              {editComponent}
            </div>
          );
        },
      },
    ];
  };

  useEffect(() => {
    const extendedColumns = props.actions
      ? extendColumnsWithActions()
      : [...props.columns];

    setExtendedColumns(extendedColumns);
  }, []);

  return (
    <div className={classes.tableOverride}>
      <DataGrid
        autoHeight
        rows={props.rows}
        columns={extendedColumns}
        pageSize={props.pageSize}
        rowsPerPageOptions={[props.pageSize]}
        rowCount={props.totalCount}
        sortingMode="server"
        paginationMode="server"
        onSortModelChange={props.onSortModelChange}
        onPageChange={props.onPageChange}
        disableColumnMenu
      />
      <Dialog open={confirmationOpen} onClose={handleClose}>
        <ConfirmationDialog
          onClose={handleClose}
          confirmObject={deleteItem}
          labelMessage={'Delete this user?'}
          confirmationText={'Are you sure you want to delete this user?'}
        />
      </Dialog>
    </div>
  );
};

export default Table;
