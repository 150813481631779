import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import React from 'react';
import { UserListFilterType, DataSource, User } from '../../types/types';
import { renderSemaphoreOrUnknown } from '../../utils/dataGridColumnRenderUtils';
import Table, { TableActions } from '../Table/Table';

const UserTable = (props: {
  dataSource: DataSource<User> | undefined;
  onFilterChange: (filters: UserListFilterType) => void;
  filters: UserListFilterType;
  onDelete?: (item: any | undefined) => void;
  onEdit?: (item: any | undefined) => void;
}) => {
  const PAGE_SIZE = 15;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'A/C-number',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'plant',
      headerName: 'Plant',
      flex: 1,
    },
    {
      field: 'sapAccount',
      headerName: 'SAP Account',
      flex: 1,
      sortable: false,
      renderCell: renderSemaphoreOrUnknown,
    },
  ];

  const tableActions: TableActions = {
    edit: true,
    delete: true,
  };

  const onPageChange = (newPage: number) => {
    props.onFilterChange({
      ...props.filters,
      from: newPage === 0 ? 0 : newPage * PAGE_SIZE,
      to: newPage === 0 ? PAGE_SIZE : newPage * PAGE_SIZE + PAGE_SIZE,
    });
  };

  const onSortModelChange = (model: GridSortModel) => {
    if (
      props.filters.orderBy === model[0]?.sort?.toString().toUpperCase() &&
      props.filters.sortBy === model[0].field
    ) {
      return;
    }

    props.onFilterChange({
      ...props.filters,
      orderBy: model[0]?.sort?.toString().toUpperCase() || '',
      sortBy: model[0]?.field,
    });
  };

  return (
    <Table
      columns={columns}
      rows={props.dataSource?.data || []}
      totalCount={props.dataSource?.totalCount}
      actions={tableActions}
      pageSize={PAGE_SIZE}
      onFilterChange={props.onFilterChange}
      onDelete={props.onDelete}
      onEdit={props.onEdit}
      onSortModelChange={onSortModelChange}
      onPageChange={onPageChange}
    />
  );
};

export default UserTable;
