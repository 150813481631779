import React, { useEffect } from 'react';

const useQuery = (
  requestFunction: () => Promise<any>,
  dependencies: any[] = []
) => {
  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  useEffect(() => {
    const makeRequest = async () => {
      setIsLoading(true);
      try {
        const res = await requestFunction();
        setResponse(res);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    makeRequest();
  }, dependencies);
  return { response, error, isLoading };
};

export default useQuery;
