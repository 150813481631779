import React from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import BackendLoginErr from './BackendLoginErr/BackendLoginErr';
import { Alert } from '@mui/material';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '25px',
  },
  userIconContainer: {
    display: 'flex',
    padding: '40px',
    borderRadius: '50%',
    background: '#cccccc',
  },
  userIcon: {
    fontSize: '65px',
  },
  marginTop: {
    marginTop: '18px',
  },
  errorBlock: {
    marginTop: '20px',
  },
}));

interface LoginProps {
  loginMethod: () => void;
  logoutMethod: () => void;
  backendAuthErr: boolean;
  error: any;
}

const Login = (props: LoginProps) => {
  const classes = useStyles();
  if (props.backendAuthErr) {
    return <BackendLoginErr logout={props.logoutMethod} error={props.error} />;
  }

  const renderErrorBlock = () => {
    if (props.error && props.error.error) {
      return (
        <Alert className={classes.errorBlock} severity="error">
          {props.error.error}
        </Alert>
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.userIconContainer}>
        <PersonOutlineIcon classes={{ root: classes.userIcon }} />
      </div>
      <Typography
        variant="h4"
        color="textSecondary"
        className={classes.marginTop}
      >
        Welcome!
      </Typography>
      <Button
        className={classes.marginTop}
        variant="contained"
        color="primary"
        onClick={props.loginMethod}
      >
        Sign In
      </Button>
      {renderErrorBlock()}
    </div>
  );
};

export default Login;
