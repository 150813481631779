import types from './types';
import { User } from '../../types/types';

export interface SessionState {
  userData: User;
}

const initialState = {
  userData: {
    id: '',
    idAlias: '',
    name: '',
    firstName: '',
    lastName: '',
    group: '',
    plant: '',
    location: '',
    sapAccount: false,
  },
};
const reducer = (
  state: SessionState = initialState,
  action: any
): SessionState => {
  switch (action.type) {
    case types.SET_SESSION_DATA:
      return {
        ...state,
        userData: action.payload.userData,
      };
    default:
      return state;
  }
};

export default reducer;
