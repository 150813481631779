import {
  Button,
  Avatar,
  Menu,
  MenuItem,
  AppBar,
  InputLabel,
  Toolbar,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import YaraLogo from '../../assets/img/yara_logo_small.png';
import useStyles from './Header.styles';
import { AuthComponentProps } from '../../hoc/AuthProvider';
import CheckIcon from '@mui/icons-material/Check';

function ProfileMenu(props: AuthComponentProps) {
  const classes = useStyles();
  const [anchorEl, setAnhcorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnhcorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnhcorEl(null);
  };

  const getUserInitials = () => {
    if (!props.user.name) return 'U';
    return props.user.firstName[0] + props.user.lastName[0];
  };

  return (
    <div className={classes.profileCard}>
      <div className={classes.userInfo}>
        <div className="name">{`${props.user?.name || 'User'}`}</div>
      </div>
      <Button
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar className={classes.avatar}>{getUserInitials()}</Avatar>
        <ArrowDropDownIcon color="secondary" />
      </Button>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={props.logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

const Header = (props: AuthComponentProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let toolbarContent = <ProfileMenu {...props} />;
  if (!props.isAuthenticated && !props.backendAuthErr)
    toolbarContent = (
      <Button onClick={props.login} className={classes.authButton}>
        Sign In
      </Button>
    );

  if (!props.isAuthenticated && props.backendAuthErr)
    toolbarContent = (
      <Button onClick={props.logout} className={classes.authButton}>
        Sign Out
      </Button>
    );
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftPart}>
          <Link to="/" className={classes.appLink}>
            <img className={classes.logo} src={YaraLogo} alt="Yara" />
          </Link>
          <InputLabel
            aria-controls="profile-menu"
            aria-haspopup="true"
            className={classes.linksItems}
            onClick={handleClick}
          >
            Notification solution
            <ArrowDropDownIcon color="secondary" />
          </InputLabel>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuItem>
              <Link
                to={{
                  pathname:
                    'https://dppdevmeabackoffice.z16.web.core.windows.net/',
                }}
                target="_blank"
              >
                Maintenance execution application
              </Link>
            </MenuItem>
            <MenuItem disabled={true}>
              <CheckIcon className={classes.checkedMenuItem} />
              Notification solution
            </MenuItem>
            <MenuItem>
              <Link
                to={{
                  pathname:
                    'https://dppdevdwsbackoffice.z13.web.core.windows.net/',
                }}
                target="_blank"
              >
                Digital worker solution
              </Link>
            </MenuItem>
          </Menu>
        </div>
        {toolbarContent}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
