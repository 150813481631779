import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

const renderBooleanValues = (params: any) => (
  <div>{params.value ? 'Yes' : 'No'}</div>
);

const renderSemaphoreOrUnknown = (params: any) => {
  if (params.value === null) {
    return 'unknown';
  }

  return (
    <CircleIcon
      style={{ fontSize: 14 }}
      color={params.value ? 'success' : 'error'}
    />
  );
};

export { renderBooleanValues, renderSemaphoreOrUnknown };
