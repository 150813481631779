import SearchIcon from '@mui/icons-material/Search';
import {
  Card,
  CardContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  Dialog,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackdropSpinner from '../../components/Spinner/BackdropSpinner';
import UserTable from '../../components/UserTable/UserTable';
import { withSnackbar, SnackbarMessage } from '../../hoc/withSnackbar';
import usePlants from '../../hooks/usePlants';
import {
  userFilterSelectors,
  userFilterOperations,
} from '../../redux/user-filter';
import { userService } from '../../services/user.service';
import { UserListFilterType, DataSource, User } from '../../types/types';
import ChangeUserDialog from './change-user-dialog';

const useStyles = makeStyles(() => ({
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '23px',
  },

  searchInput: {
    width: '30%',
  },

  selectFilters: {
    width: '30%',
  },
}));

const UsersAdmin = (props: {
  snackbarShowMessage: (message: SnackbarMessage) => void;
}) => {
  const classes = useStyles();

  const [dataSource, setDataSource] = useState<DataSource<User> | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [editableUser, setEditableUser] = useState<User>();

  const dispatch = useDispatch();
  const filters = useSelector(userFilterSelectors.getUserListFilterType);
  const { plants } = usePlants();

  useEffect(() => {
    fetchUsers(filters);
  }, [filters]);

  const fetchUsers = (filters: UserListFilterType) => {
    setLoading(true);

    userService
      .getUsers(filters)
      .then((response: any) => {
        const { users: payload, totalCount } = response.data;

        setDataSource(new DataSource<User>(payload, totalCount));
      })
      .catch(() => {
        props.snackbarShowMessage({
          message: 'Users fetching failed',
          severity: 'error',
        });
      })
      .finally(() => setLoading(() => false));
  };

  const filtersChangedHandler = (newFilters: UserListFilterType) => {
    dispatch(userFilterOperations.setUserFilterData(newFilters));
  };

  const onSearch = (e: any, currentFilters: UserListFilterType) => {
    if (e.target.value.length > 2 || !e.target.value.length) {
      filtersChangedHandler({
        ...currentFilters,
        search: e.target.value,
      });
    }
  };

  const handleValueChange = useCallback(
    debounce((event) => onSearch(event, filters), 300),
    [filters]
  );

  const onDelete = (e: any) => {
    userService
      .deleteUser(e.id)
      .then(() => {
        props.snackbarShowMessage({
          message: 'User was successfully deleted',
          severity: 'success',
        });

        setLoading(true);
        fetchUsers(filters);
      })
      .catch(() => {
        props.snackbarShowMessage({
          message: 'User delete failed',
          severity: 'error',
        });
      })
      .finally(() => setLoading(() => false));
  };

  const onEdit = (e: any) => {
    setEditableUser(e);
    handleClickOpen();
  };

  const onPlantSelect = (e: any) => {
    filtersChangedHandler({
      ...filters,
      plant: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setIsAddUserModalOpen(true);
  };

  const handleClose = (user?: User) => {
    setIsAddUserModalOpen(false);
    setEditableUser(undefined);

    if (user) {
      props.snackbarShowMessage({
        message: 'User saved successfully',
        severity: 'success',
      });

      fetchUsers(filters);
    }
  };

  return (
    <Card>
      <CardContent>
        <div className={classes.controls}>
          <Button variant="contained" onClick={handleClickOpen}>
            + Add User
          </Button>
          <FormControl variant="outlined" className={classes.selectFilters}>
            <InputLabel id="plant">Plant</InputLabel>
            <Select
              labelId="plant"
              label="Plant"
              onChange={onPlantSelect}
              defaultValue={''}
            >
              <MenuItem value="">
                <em>Clear filter</em>
              </MenuItem>
              {plants?.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <OutlinedInput
            id="input-with-icon-adornment"
            className={classes.searchInput}
            onChange={handleValueChange}
            placeholder={'Find user by name of A/C number'}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </div>
        <UserTable
          dataSource={dataSource}
          filters={filters}
          onFilterChange={filtersChangedHandler}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </CardContent>
      <Dialog open={isAddUserModalOpen} fullWidth maxWidth={'sm'}>
        <ChangeUserDialog
          onClose={handleClose}
          plants={plants || []}
          editableUser={editableUser}
          snackbarShowMessage={props.snackbarShowMessage}
        />
      </Dialog>
      <BackdropSpinner isOpened={loading} />
    </Card>
  );
};

export default withSnackbar(UsersAdmin);
