import {
  AuthenticationResult,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';

import { config } from '../Config';

export default class AzureMsalService {
  private publicClientApplication: PublicClientApplication;

  constructor(customConfig = config) {
    this.publicClientApplication = new PublicClientApplication({
      auth: {
        clientId: customConfig.appId || '',
        redirectUri: customConfig.redirectUri,
        authority: `https://login.microsoftonline.com/${customConfig.azureTenantId}`,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
    });
  }

  loginRedirect(
    loginRedirectConfig = {
      scopes: config.scopes,
      prompt: 'select_account',
    }
  ) {
    this.publicClientApplication.loginRedirect(loginRedirectConfig);
  }

  handleRedirectPromise(): Promise<AuthenticationResult | null> {
    return this.publicClientApplication.handleRedirectPromise();
  }

  getAllAccounts() {
    return this.publicClientApplication.getAllAccounts();
  }

  acquireTokenSilent(params: SilentRequest): Promise<AuthenticationResult> {
    return this.publicClientApplication.acquireTokenSilent(params);
  }

  acquireTokenRedirect(params: RedirectRequest): Promise<void> {
    return this.publicClientApplication.acquireTokenRedirect(params);
  }

  logout() {
    this.publicClientApplication.logout();
  }
}
