import { InputLabel, Select, MenuItem } from '@mui/material';
import React from 'react';
import { Controller, Control } from 'react-hook-form';

const FormSelect = (props: {
  control: Control<any>;
  label: string;
  controlName: string;
  options: any[];
  optionsKey?: string;
  required?: boolean;
}) => {
  const { control, label, controlName, options, optionsKey, required } = props;

  return (
    <>
      <InputLabel id={label} required={required}>
        {label}
      </InputLabel>
      <Controller
        name={controlName}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            fullWidth
            variant="outlined"
            {...field}
            defaultValue={''}
            label={label}
            labelId={label}
          >
            <MenuItem value="">
              <em>Empty value</em>
            </MenuItem>
            {options.map((option) => {
              const resultOption = optionsKey ? option[optionsKey] : option;

              return (
                <MenuItem key={resultOption} value={resultOption}>
                  {resultOption}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
    </>
  );
};

export default FormSelect;
