import makeStyles from '@mui/styles/makeStyles';

const headerStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
  },
  toolbar: {
    justifyContent: 'space-between',
    padding: 8,
    paddingRight: 0,
  },
  appLink: {
    display: 'flex',
  },
  logo: {
    width: 44,
    height: 44,
    padding: 3,
    background: theme.palette.secondary.main,
  },
  avatar: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  profileCard: {
    display: 'flex',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontWeight: 600,
  },
  userPosition: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  authButton: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
  },
  linksItems: {
    display: 'flex',
    fontSize: 21,
    color: theme.palette.secondary.main,
    marginLeft: 20,
    alignItems: 'center',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  leftPart: {
    display: 'flex',
    alignItems: 'center',
  },
  checkedMenuItem: {
    position: 'absolute',
    left: 7,
  },
}));

export default headerStyles;
