import { AxiosResponse } from 'axios';
import { removeEmptyParams } from '../utils/removeEmptyParams';
import { requestHandler } from './requestHandler';
import { User, UserListFilterType, DataSource } from '../types/types';

const _baseUserUrl = '/api/users';

export const userService = {
  getUserByUserId: (userId: string): Promise<AxiosResponse<User>> => {
    return requestHandler.get(`${_baseUserUrl}/${userId}`);
  },

  getUsers: (
    filters?: UserListFilterType
  ): Promise<AxiosResponse<DataSource<User>>> => {
    return requestHandler.get(`${_baseUserUrl}`, {
      params: {
        ...removeEmptyParams(filters),
      },
    });
  },

  deleteUser: (id: string): Promise<AxiosResponse> => {
    return requestHandler.delete(`${_baseUserUrl}/${id}`);
  },

  createUser: (body: User): Promise<AxiosResponse<User>> => {
    return requestHandler.post(`${_baseUserUrl}`, removeEmptyParams(body));
  },

  updateUser: (id: string, body: User): Promise<AxiosResponse<User>> => {
    return requestHandler.put(`${_baseUserUrl}/${id}`, removeEmptyParams(body));
  },
};
