import { InputLabel, OutlinedInput } from '@mui/material';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

const FormInput = (props: {
  label: string;
  register: UseFormRegisterReturn;
  required?: boolean;
  disabled?: boolean;
}) => {
  const { label, register, required, disabled } = props;

  return (
    <>
      <InputLabel required={required} htmlFor={label}>
        {label}
      </InputLabel>
      <OutlinedInput
        {...register}
        fullWidth
        label={label}
        id={label}
        disabled={disabled}
      />
    </>
  );
};

export default FormInput;
