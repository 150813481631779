import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '25px',
  },
  errorHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '52px',
    color: '#828282',
    '& .MuiSvgIcon-root': {
      fontSize: '52px',
      marginRight: '10px',
    },
  },
  errorMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 5px',
    alignItems: 'center',
    fontSize: '16px',
  },
}));

interface BackendLoginErrProps {
  logout: () => void;
  error: any;
}

const errorMessages: any = {
  Forbidden_Factory:
    'Your Yara location has not been activated to use the Notification App',
};

const defaultMessage = `Current user isn't registered in our database.`;

const BackendLoginErr = (props: BackendLoginErrProps) => {
  const classes = useStyles();
  const keys = props.error && props.error.message.split(' ');
  const errorMessage =
    keys && keys.length > 0 ? errorMessages[keys[1]] : defaultMessage;
  return (
    <div className={classes.wrapper}>
      <span className={classes.errorHeader}>
        <ErrorOutlineIcon /> 401
      </span>
      <div className={classes.errorMessageContainer}>
        <span>{errorMessage}</span>
        <span>Sign out to continue.</span>
      </div>

      <Button onClick={props.logout} variant="contained">
        Sign Out
      </Button>
    </div>
  );
};

export default BackendLoginErr;
