import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress } from '@mui/material';
import React from 'react';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 60px)',
  },
}));

const Spinner = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <CircularProgress />
    </div>
  );
};

export default Spinner;
