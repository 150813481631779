export class User {
  id: string;
  idAlias?: string;
  name: string;
  firstName: string;
  lastName: string;
  group?: string;
  plant?: string;
  location: string;
  sapAccount?: boolean | null;
  editableUser?: string;

  constructor() {
    this.id = '';
    this.idAlias = '';
    this.name = '';
    this.firstName = '';
    this.lastName = '';
    this.group = '';
    this.plant = '';
    this.location = '';
    this.sapAccount = false;
    this.editableUser = '';
  }
}

export class DataSource<T> {
  totalCount: number;
  data: T[];

  constructor(data: T[], totalCount: number) {
    this.data = data;
    this.totalCount = totalCount;
  }
}

export interface UserListFilterType {
  to: number;
  from: number;
  search: string;
  orderBy: string;
  sortBy: string;
  plant: string;
}
